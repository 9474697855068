.column-left {
    width: 40%;
    vertical-align: text-top !important;
}

.column-right {
    width: 60%;
    vertical-align: text-top !important;
}

.cart_product_img {
    width:20%
}
.cart_product_img img {
    width: 60%;
    margin: 0 auto;
    display: block;
}

.sku-style {
    font-size: 12px;
    display: block;
}